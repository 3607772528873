// Popover
(function($) {

	'use strict';

	if ( $.isFunction( $.fn['popover'] ) ) {
		$( '[data-toggle=popover]' ).popover();
	}

}).apply(this, [jQuery]);

// Tooltip
(function($) {

	'use strict';

	if ( $.isFunction( $.fn['tooltip'] ) ) {
		$( '[data-toggle=tooltip],[rel=tooltip]' ).tooltip({ container: 'body' });
	}

}).apply(this, [jQuery]);

// Scroll to Top
(function(theme, $) {
	// Scroll to Top Button.
	if (typeof theme.PluginScrollToTop !== 'undefined') {
		theme.PluginScrollToTop.initialize();
	}
}).apply(this, [window.theme, jQuery]);

// Sidebar Widgets
(function($) {

	'use strict';

	function expand( content ) {
		content.children( '.widget-content' ).slideDown( 'fast', function() {
			$(this).css( 'display', '' );
			content.removeClass( 'widget-collapsed' );
		});
	}

	function collapse( content ) {
		content.children('.widget-content' ).slideUp( 'fast', function() {
			content.addClass( 'widget-collapsed' );
			$(this).css( 'display', '' );
		});
	}

	var $widgets = $( '.sidebar-widget' );

	$widgets.each( function() {

		var $widget = $( this ),
			$toggler = $widget.find( '.widget-toggle' );

		$toggler.on('click.widget-toggler', function() {
			$widget.hasClass('widget-collapsed') ? expand($widget) : collapse($widget);
		});
	});

}).apply(this, [jQuery]);

// iosSwitcher
(function($) {

	'use strict';

	if ( $.isFunction( $.fn.confirmation ) ) {

		$.extend( $.fn.confirmation.Constructor.DEFAULTS, {
			btnOkIcon 		: 'fa fa-check',
			btnCancelIcon 	: 'fa fa-times'
		});

	}

}).apply(this, [jQuery]);

// Codemirror
(function($) {

	'use strict';

	if ( typeof CodeMirror !== 'undefined' ) {

		$(function() {
			$('[data-plugin-codemirror]').each(function() {
				var $this = $( this ),
					opts = {};

				var pluginOptions = $this.data('plugin-options');
				if (pluginOptions)
					opts = pluginOptions;

				$this.themePluginCodeMirror(opts);
			});
		});

	}

}).apply(this, [jQuery]);

// Colorpicker
(function($) {

	'use strict';

	if ( $.isFunction($.fn[ 'colorpicker' ]) ) {

		$(function() {
			$('[data-plugin-colorpicker]').each(function() {
				var $this = $( this ),
					opts = {};

				var pluginOptions = $this.data('plugin-options');
				if (pluginOptions)
					opts = pluginOptions;

				$this.themePluginColorPicker(opts);
			});
		});

	}

}).apply(this, [jQuery]);

// Datepicker
(function($) {

	'use strict';

	if ( $.isFunction($.fn[ 'datepicker' ]) ) {

		$(function() {
			$('[data-plugin-datepicker]').each(function() {
				var $this = $( this ),
					opts = {};

				var pluginOptions = $this.data('plugin-options');
				if (pluginOptions)
					opts = pluginOptions;

				$this.themePluginDatePicker(opts);
			});
		});

	}

}).apply(this, [jQuery]);

// iosSwitcher
(function($) {

	'use strict';

	if ( typeof Switch !== 'undefined' && $.isFunction( Switch ) ) {

		$(function() {
			$('[data-plugin-ios-switch]').each(function() {
				var $this = $( this );

				$this.themePluginIOS7Switch();
			});
		});

	}

}).apply(this, [jQuery]);

// Markdown
(function($) {

	'use strict';

	if ( $.isFunction($.fn[ 'markdown' ]) ) {

		$(function() {
			$('[data-plugin-markdown-editor]').each(function() {
				var $this = $( this ),
					opts = {};

				var pluginOptions = $this.data('plugin-options');
				if (pluginOptions)
					opts = pluginOptions;

				$this.themePluginMarkdownEditor(opts);
			});
		});

	}

}).apply(this, [jQuery]);

// Masked Input
(function($) {

	'use strict';

	if ( $.isFunction($.fn[ 'mask' ]) ) {

		$(function() {
			$('[data-plugin-masked-input]').each(function() {
				var $this = $( this ),
					opts = {};

				var pluginOptions = $this.data('plugin-options');
				if (pluginOptions)
					opts = pluginOptions;

				$this.themePluginMaskedInput(opts);
			});
		});

	}

}).apply(this, [jQuery]);

// MaxLength
(function($) {

	'use strict';

	if ( $.isFunction( $.fn[ 'maxlength' ]) ) {

		$(function() {
			$('[data-plugin-maxlength]').each(function() {
				var $this = $( this ),
					opts = {};

				var pluginOptions = $this.data('plugin-options');
				if (pluginOptions)
					opts = pluginOptions;

				$this.themePluginMaxLength(opts);
			});
		});

	}

}).apply(this, [jQuery]);

// MultiSelect
(function($) {

	'use strict';

	if ( $.isFunction( $.fn[ 'multiselect' ] ) ) {

		$(function() {
			$( '[data-plugin-multiselect]' ).each(function() {

				var $this = $( this ),
					opts = {};

				var pluginOptions = $this.data('plugin-options');
				if (pluginOptions)
					opts = pluginOptions;

				$this.themePluginMultiSelect(opts);

			});
		});

	}

}).apply(this, [jQuery]);

(function($) {

	'use strict';

	if ( $.isFunction( $.fn[ 'placeholder' ]) ) {

		$('input[placeholder]').placeholder();

	}

}).apply(this, [jQuery]);


// Select2
(function($) {

	'use strict';

	if ( $.isFunction($.fn[ 'select2' ]) ) {

		$(function() {
			$('[data-plugin-selectTwo]').each(function() {
				var $this = $( this ),
					opts = {};

				var pluginOptions = $this.data('plugin-options');
				if (pluginOptions)
					opts = pluginOptions;

				$this.themePluginSelect2(opts);
			});
		});

	}

}).apply(this, [jQuery]);

// Spinner
(function($) {

	'use strict';

	if ( $.isFunction($.fn[ 'spinner' ]) ) {

		$(function() {
			$('[data-plugin-spinner]').each(function() {
				var $this = $( this ),
					opts = {};

				var pluginOptions = $this.data('plugin-options');
				if (pluginOptions)
					opts = pluginOptions;

				$this.themePluginSpinner(opts);
			});
		});

	}

}).apply(this, [jQuery]);

// SummerNote
(function($) {

	'use strict';

	if ( $.isFunction($.fn[ 'summernote' ]) ) {

		$(function() {
			$('[data-plugin-summernote]').each(function() {
				var $this = $( this ),
					opts = {};

				var pluginOptions = $this.data('plugin-options');
				if (pluginOptions)
					opts = pluginOptions;

				$this.themePluginSummerNote(opts);
			});
		});

	}

}).apply(this, [jQuery]);

// TextArea AutoSize
(function($) {

	'use strict';

	if ( typeof autosize === 'function' ) {

		$(function() {
			$('[data-plugin-textarea-autosize]').each(function() {
				var $this = $( this ),
					opts = {};

				var pluginOptions = $this.data('plugin-options');
				if (pluginOptions)
					opts = pluginOptions;

				$this.themePluginTextAreaAutoSize(opts);
			});
		});

	}

}).apply(this, [jQuery]);

// TimePicker
(function($) {

	'use strict';

	if ( $.isFunction($.fn[ 'timepicker' ]) ) {

		$(function() {
			$('[data-plugin-timepicker]').each(function() {
				var $this = $( this ),
					opts = {};

				var pluginOptions = $this.data('plugin-options');
				if (pluginOptions)
					opts = pluginOptions;

				$this.themePluginTimePicker(opts);
			});
		});

	}

}).apply(this, [jQuery]);

// Mailbox
(function($) {

	'use strict';

	$(function() {
		$('[data-mailbox]').each(function() {
			var $this = $( this );

			$this.themeMailbox();
		});
	});

}).apply(this, [jQuery]);

// Animate
(function($) {

	'use strict';

	if ( $.isFunction($.fn[ 'appear' ]) ) {

		$(function() {
			$('[data-plugin-animate], [data-appear-animation]').each(function() {
				var $this = $( this ),
					opts = {};

				var pluginOptions = $this.data('plugin-options');
				if (pluginOptions)
					opts = pluginOptions;

				$this.themePluginAnimate(opts);
			});
		});

	}

}).apply(this, [jQuery]);

// Carousel
(function($) {

	'use strict';

	if ( $.isFunction($.fn[ 'owlCarousel' ]) ) {

		$(function() {
			$('[data-plugin-carousel]').each(function() {
				var $this = $( this ),
					opts = {};

				var pluginOptions = $this.data('plugin-options');
				if (pluginOptions)
					opts = pluginOptions;

				$this.themePluginCarousel(opts);
			});
		});

	}

}).apply(this, [jQuery]);

// Chart Circular
(function($) {

	'use strict';

	if ( $.isFunction($.fn[ 'easyPieChart' ]) ) {

		$(function() {
			$('[data-plugin-chart-circular], .circular-bar-chart:not(.manual)').each(function() {
				var $this = $( this ),
					opts = {};

				var pluginOptions = $this.data('plugin-options');
				if (pluginOptions)
					opts = pluginOptions;

				$this.themePluginChartCircular(opts);
			});
		});

	}

}).apply(this, [jQuery]);

// Lightbox
(function($) {

	'use strict';

	if ( $.isFunction($.fn[ 'magnificPopup' ]) ) {

		$(function() {
			$('[data-plugin-lightbox], .lightbox:not(.manual)').each(function() {
				var $this = $( this ),
					opts = {};

				var pluginOptions = $this.data('plugin-options');
				if (pluginOptions)
					opts = pluginOptions;

				$this.themePluginLightbox(opts);
			});
		});

	}

}).apply(this, [jQuery]);

// Portlets
(function($) {

	'use strict';

	if ( typeof NProgress !== 'undefined' && $.isFunction( NProgress.configure ) ) {

		NProgress.configure({
			showSpinner: false,
			ease: 'ease',
			speed: 750
		});

	}

}).apply(this, [jQuery]);

// Portlets
(function($) {

	'use strict';

	$(function() {
		$('[data-plugin-portlet]').each(function() {
			var $this = $( this ),
				opts = {};

			var pluginOptions = $this.data('plugin-options');
			if (pluginOptions)
				opts = pluginOptions;

			$this.themePluginPortlet(opts);
		});
	});

}).apply(this, [jQuery]);

// Scrollable
(function($) {

	'use strict';

	if ( $.isFunction($.fn[ 'nanoScroller' ]) ) {

		$(function() {
			$('[data-plugin-scrollable]').each(function() {
				var $this = $( this ),
					opts = {};

				var pluginOptions = $this.data('plugin-options');
				if (pluginOptions) {
					opts = pluginOptions;
				}

				$this.themePluginScrollable(opts);
			});
		});

	}

}).apply(this, [jQuery]);

// Slider
(function($) {

	'use strict';

	if ( $.isFunction($.fn[ 'slider' ]) ) {

		$(function() {
			$('[data-plugin-slider]').each(function() {
				var $this = $( this ),
					opts = {};

				var pluginOptions = $this.data('plugin-options');
				if (pluginOptions) {
					opts = pluginOptions;
				}

				$this.themePluginSlider(opts);
			});
		});

	}

}).apply(this, [jQuery]);

// Toggle
(function($) {

	'use strict';

	$(function() {
		$('[data-plugin-toggle]').each(function() {
			var $this = $( this ),
				opts = {};

			var pluginOptions = $this.data('plugin-options');
			if (pluginOptions)
				opts = pluginOptions;

			$this.themePluginToggle(opts);
		});
	});

}).apply(this, [jQuery]);

// Widget - Todo
(function($) {

	'use strict';

	if ( $.isFunction($.fn[ 'themePluginWidgetTodoList' ]) ) {

		$(function() {
			$('[data-plugin-todo-list], ul.widget-todo-list').each(function() {
				var $this = $( this ),
					opts = {};

				var pluginOptions = $this.data('plugin-options');
				if (pluginOptions)
					opts = pluginOptions;

				$this.themePluginWidgetTodoList(opts);
			});
		});

	}

}).apply(this, [jQuery]);

// Widget - Toggle
(function($) {

	'use strict';

	if ( $.isFunction($.fn[ 'themePluginWidgetToggleExpand' ]) ) {

		$(function() {
			$('[data-plugin-toggle-expand], .widget-toggle-expand').each(function() {
				var $this = $( this ),
					opts = {};

				var pluginOptions = $this.data('plugin-options');
				if (pluginOptions)
					opts = pluginOptions;

				$this.themePluginWidgetToggleExpand(opts);
			});
		});
	}

}).apply(this, [jQuery]);

// Word Rotate
(function($) {

	'use strict';

	if ( $.isFunction($.fn[ 'themePluginWordRotate' ]) ) {

		$(function() {
			$('[data-plugin-word-rotate], .word-rotate:not(.manual)').each(function() {
				var $this = $( this ),
					opts = {};

				var pluginOptions = $this.data('plugin-options');
				if (pluginOptions)
					opts = pluginOptions;

				$this.themePluginWordRotate(opts);
			});
		});

	}

}).apply(this, [jQuery]);